const dashboard = 'dashboard';
const inputSourceConnection = 'input_source_connections';
const inputSourceMaster = 'input_source_type_master';
const inputSourceFileUpload = 'input_data_file_upload';
const inputSourceColumn = 'input_source_column';
const masterSourceConnection = 'master_source_connections';
const masterSourceColumn = 'master_source_column';
const masterSourceMaster = 'master_source_type_master';
const userEvalPermissionDetails = 'user_eval_permission_details';
const updateUserLanguage = 'update_user_language';
const organization = 'organization';
const userRoles = 'get_user_roles_program_details';
const updateUserRoleProgramDetails = 'update_user_role_program_details';
const incentiveProgram = 'incentive_program';

const apiEndpoint = {
  dashboard,
  inputSourceConnection,
  inputSourceMaster,
  inputSourceFileUpload,
  inputSourceColumn,
  masterSourceConnection,
  masterSourceColumn,
  masterSourceMaster,
  userEvalPermissionDetails,
  updateUserLanguage,
  organization,
  userRoles,
  updateUserRoleProgramDetails,
  incentiveProgram,
};

export default apiEndpoint;
