const dashboard = '/dashboard';
const login = '/login';
const error = '/error';
const profile = '/profile';

const routeName = {
  dashboard,
  login,
  error,
  profile,
};

export default routeName;
