// Package Imports
import { MetaQuery } from '@refinedev/core';

// Project Imports
import axiosInstance from 'providers/axiosInstance/axiosInstance';

type OneParams = {
  apiUrl: string;
  resource: string;
  id: string | number;
  meta?: MetaQuery;
};

const getOne = async ({ resource, id, meta, apiUrl }: OneParams) => {
  let url = `${apiUrl}/${resource}/${id}`;
  const customfilter = meta?.customfilter ?? null;
  if (customfilter !== null) {
    url += `&userName=${customfilter.username}`;
  }

  const { data, headers } = await axiosInstance.get(url);

  return {
    data,
    headers,
  };
};

export default getOne;
