// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const DashboardResource: ResourceItem = {
  name: resourceName.dashboard,
  identifier: resourceName.dashboard,
  list: `${resourceName.dashboard}`,
  options: { label: 'Dashboard' },
};

export default DashboardResource;
