const dashboard = 'dashboard';
const login = 'login';
const error = 'error';
const profile = 'profile';

const resourceName = {
  dashboard,
  login,
  error,
  profile,
};

export default resourceName;
