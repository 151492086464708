import React from 'react';
import { DevtoolsProvider, DevtoolsPanel } from '@refinedev/devtools';
import { notificationProvider } from '@refinedev/antd';
import { type AuthBindings, Refine } from '@refinedev/core';
import { RefineKbar, RefineKbarProvider } from '@refinedev/kbar';
import routerBindings, {
  DocumentTitleHandler,
  UnsavedChangesNotifier,
} from '@refinedev/react-router-v6';

import AppRoutes from 'core/routes/routes';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import ConfigStyle from 'core/constants/ConfigStyle';
import { useTranslation } from 'react-i18next';

import resources from 'core/resources/resources';
import customDataProvider from 'providers/DataProvder';
import { useTokenContext } from 'contexts/resource/ResourceContext';

type InitiateProps = {
  authProvider: AuthBindings;
};

type I18nProviderType = {
  translate: (key: string, options?: Record<string, unknown>) => string;
  changeLocale: (lang: string) => Promise<void>;
  getLocale: () => string; // Add other methods or properties with their respective types
};

const Initiate: React.FC<InitiateProps> = ({ authProvider }) => {
  const customTitleHandler = () => {
    const title = 'Shell App';
    return title;
  };

  const { filteredResources } = useTokenContext();

  const { t, i18n } = useTranslation();
  const i18nProvider: I18nProviderType = {
    translate: (key: string, options?: Record<string, unknown>) =>
      t(key, options),
    async changeLocale(lang: string): Promise<void> {
      await i18n.changeLanguage(lang); // Optionally handle any additional logic after changing the language
    },
    getLocale: () => i18n.language,
  };

  const userDataProvider = customDataProvider(process.env.REACT_APP_API_URL);
  const organizationDataProvider = customDataProvider(
    process.env.REACT_APP_API_URL_ORG
  );

  console.log(resources, filteredResources, 'resources');
  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ConfigProvider {...ConfigStyle}>
          <DevtoolsProvider>
            <Refine
              dataProvider={{
                default: userDataProvider,
                organizationDataProvider,
              }}
              i18nProvider={i18nProvider}
              notificationProvider={notificationProvider}
              routerProvider={routerBindings}
              authProvider={authProvider}
              resources={filteredResources}
              options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: true,
                projectId: 'yFsPTU-0WFGC2-VwzawS',
              }}
            >
              <AppRoutes />
              <RefineKbar />
              <UnsavedChangesNotifier />
              <DocumentTitleHandler handler={customTitleHandler} />
            </Refine>
            <DevtoolsPanel />
          </DevtoolsProvider>
        </ConfigProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
};

export default Initiate;
