// Package Imports
import React, { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';

// Project Imports
import routeName from 'core/common/routeName';
import Loading from 'components/loading/loading';
import resourceName from 'core/common/resourceName';
import PrivateRoute from 'core/utils/PrivateRoute';

// Lazy-loaded Component
const Dashboard = lazy(() => import('pages/dashboard/dashboard'));

const DashboardRoute = (
  <Route
    path={routeName.dashboard}
    element={
      <PrivateRoute name={resourceName.dashboard}>
        <Suspense fallback={<Loading />}>
          <Dashboard />
        </Suspense>
      </PrivateRoute>
    }
  />
);

export default DashboardRoute;
